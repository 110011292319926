// Constants
export const KEYPOINTS_PER_PERSON = 17;
export const KEYPOINT_DIMENSIONS = 3; // x, y, z coordinates
export const BBOX_DIMENSIONS = 4; // x, y, width, height
export const DECIMAL_PRECISION = 100; // Multiply by 100 to keep 2 decimal places
export const MIN_INT = -2147483648; // Used to represent undefined values in buffers

// Comprehensive list of angle properties organized by category
export const ANGLE_PROPERTIES = {
  basicAngles: [
    "neck",
    "back",
    "rightKnee",
    "leftKnee",
    "rightUpperArm",
    "leftUpperArm",
    "rightLowerArm",
    "leftLowerArm",
    "rightWrist",
    "leftWrist",
  ],
  planeAngles: [
    { joint: "neck", planes: ["sagittal", "coronal", "axial"] },
    { joint: "back", planes: ["sagittal", "coronal", "axial"] },
    { joint: "rightKnee", planes: ["sagittal", "coronal", "axial"] },
    { joint: "leftKnee", planes: ["sagittal", "coronal", "axial"] },
    { joint: "rightUpperArm", planes: ["sagittal", "coronal", "axial"] },
    { joint: "leftUpperArm", planes: ["sagittal", "coronal", "axial"] },
    { joint: "rightLowerArm", planes: ["sagittal", "coronal", "axial"] },
    { joint: "leftLowerArm", planes: ["sagittal", "coronal", "axial"] },
  ],
  wristPlanes: [
    {
      joint: "rightWrist",
      planes: ["rightWristSagittal", "rightWristCoronal"],
    },
    { joint: "leftWrist", planes: ["leftWristSagittal", "leftWristCoronal"] },
  ],
};

// Generate flattened list of all angle properties
export const FLATTENED_ANGLE_PROPERTIES = [
  ...ANGLE_PROPERTIES.basicAngles,
  ...ANGLE_PROPERTIES.planeAngles.flatMap(({ joint, planes }) =>
    planes.map((plane) => `${joint}Plane.${plane}`),
  ),
  ...ANGLE_PROPERTIES.wristPlanes.flatMap(({ joint, planes }) =>
    planes.map((plane) => `${joint}Plane.${plane}`),
  ),
];
