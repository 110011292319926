import {
  CompressedAnglesRo,
  CompressedPoseDataRo,
} from "src/app/services/generatedApi";
import {
  KEYPOINT_DIMENSIONS,
  BBOX_DIMENSIONS,
  DECIMAL_PRECISION,
  MIN_INT,
} from "./constants";
import { setNestedValue } from "./utilities";
import { AnglesRo, PersonFrameRo } from "src/types/poseType";

function decompressKeypoints(
  compressed: CompressedPoseDataRo,
  frameIndex: number,
): number[][] {
  try {
    const keypoints: number[][] = [];
    const baseIndex =
      frameIndex * compressed.keypointCount * KEYPOINT_DIMENSIONS;

    for (let i = 0; i < compressed.keypointCount; i++) {
      const point: number[] = [];
      const pointBaseIndex = baseIndex + i * KEYPOINT_DIMENSIONS;

      for (let d = 0; d < KEYPOINT_DIMENSIONS; d++) {
        const value = compressed.keypointsBuffer[pointBaseIndex + d];
        point.push(value / DECIMAL_PRECISION);
      }

      keypoints.push(point);
    }

    return keypoints;
  } catch (error) {
    console.error("Error decompressing keypoints:", error);
    return [];
  }
}

function decompressAngles(
  compressed: CompressedAnglesRo,
  frameIndex: number,
): AnglesRo {
  try {
    const angles = new AnglesRo();
    const baseIndex = frameIndex * compressed.anglesMapping.length;

    compressed.anglesMapping.forEach((prop, index) => {
      const value = compressed.anglesBuffer[baseIndex + index];
      if (value !== MIN_INT) {
        setNestedValue(angles, prop, value);
      }
    });

    return angles;
  } catch (error) {
    console.error("Error decompressing angles:", error);
    return new AnglesRo();
  }
}

function typedArraySlice(
  array: number[] | Int32Array | Int16Array,
  start: number,
  end: number,
): number[] {
  const result: number[] = [];
  for (let i = start; i < end && i < array.length; i++) {
    result.push(array[i]);
  }
  return result;
}

export function decompress(
  compressed: CompressedPoseDataRo,
): Record<string, PersonFrameRo[]> {
  try {
    const personFrames: Record<string, PersonFrameRo[]> = {};

    compressed.personIdDictionary.forEach((personId) => {
      personFrames[personId] = [];
    });

    for (let i = 0; i < compressed.frameCount; i++) {
      const personIdIndex = compressed.personIdIndices[i];
      const personId = compressed.personIdDictionary[personIdIndex];
      const frame = new PersonFrameRo();

      frame.frameIndex = compressed.frameIndices[i];
      frame.keypoints = decompressKeypoints(compressed, i);

      if (compressed.angles) {
        frame.angles = decompressAngles(compressed.angles, i);
      }

      if (compressed.bboxBuffer) {
        frame.bbox = typedArraySlice(
          compressed.bboxBuffer,
          i * BBOX_DIMENSIONS,
          (i + 1) * BBOX_DIMENSIONS,
        ).map((value) => value / DECIMAL_PRECISION);
      }

      frame.isBest = compressed.isBestBuffer?.[i] === 1;

      personFrames[personId].push(frame);
    }

    return personFrames;
  } catch (error) {
    console.error("Error in decompress function:", error);
    throw error;
  }
}
