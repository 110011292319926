import { Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaControllerGetMediaUrlQuery } from "src/app/services/generatedApi";
import { useEventEmitter } from "src/components/hook/useEventEmitter";
import { TaskContext } from "src/context/TaskContext";
import { DomVideoPlayer } from "./dom/DomVideoPlayer";
import BoundingBoxOverlay from "./BoundingBoxOverlay";

export type VideoPlayerPropsType = {
  src: string;
  size: { width: number; height: number };
  controls?: boolean;
  mouseListening?: boolean;
  initSelectedPersonId?: string;
  onUpdate: () => void;
  onLoaded: () => void;
};

type VideoPlayerBasePropsType = {
  fileName: string;
  controls?: boolean;
  mouseListening?: boolean;
  selectedFrameIndex?: number;
  maxHeight?: number;
};

export const VideoPlayer: FC<VideoPlayerBasePropsType> = ({
  fileName,
  controls = true,
  mouseListening = true,
  selectedFrameIndex,
  maxHeight = 400,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [size, setSize] = useState({ width: 300, height: 400 });
  const lastVideoRatio = useRef(0.75);

  const { selectedPersonBBoxes, fps } = useContext(TaskContext);

  const getCurrentBBox = useCallback(() => {
    if (!videoRef.current || !fps) return [];
    const frameIndex =
      selectedFrameIndex ?? Math.floor(videoRef.current.currentTime * fps);
    return selectedPersonBBoxes[frameIndex];
  }, [fps, selectedFrameIndex, selectedPersonBBoxes]);

  const fixSize = useCallback(() => {
    if (
      !videoRef?.current?.videoWidth ||
      !videoRef?.current?.videoHeight ||
      !containerRef.current?.offsetWidth
    )
      return;

    const videoRatio =
      videoRef.current.videoWidth / videoRef.current.videoHeight;
    if (lastVideoRatio.current === videoRatio) return;

    const maxWidth = containerRef.current.offsetWidth;

    const [newWidth, newHeight] =
      videoRatio > maxWidth / maxHeight
        ? [maxWidth, maxWidth / videoRatio]
        : [maxHeight * videoRatio, maxHeight];

    setSize({
      width: newWidth,
      height: newHeight,
    });
    lastVideoRatio.current = videoRatio;
    containerRef.current.style.width = newWidth + "px";
    containerRef.current.style.height = newHeight + "px";
  }, [maxHeight]);

  const { emit, on, off } = useEventEmitter();

  useEffect(() => {
    const onChangeTime = (currentTime: number) => {
      if (!videoRef.current || selectedFrameIndex !== undefined) return;
      videoRef.current.currentTime = currentTime;
    };

    on("changeTime_chart", onChangeTime);
    return () => {
      off("changeTime_chart", onChangeTime);
    };
  }, [off, on, selectedFrameIndex]);

  const { setDuration } = useContext(TaskContext);

  const onLoaded = useCallback(() => {
    fixSize();
    videoRef.current?.pause();
    if (videoRef.current?.duration) {
      if (selectedFrameIndex && fps) {
        videoRef.current.currentTime = selectedFrameIndex / fps;
      } else {
        videoRef.current.currentTime = 0;
      }
      setDuration(Math.round(videoRef.current?.duration * 100) / 100);
    }
  }, [fps, fixSize, selectedFrameIndex, setDuration]);

  const onUpdate = useCallback(() => {
    if (!videoRef.current) return;
    fixSize();
    if (selectedFrameIndex !== undefined) return;
    emit("changeTime_video", videoRef.current.currentTime);
  }, [emit, fixSize, selectedFrameIndex]);

  const { data: mediaUrl, isLoading: getOutMediaUrlLoading } =
    useMediaControllerGetMediaUrlQuery(
      {
        mediaName: fileName,
      },
      { skip: !fileName },
    );

  return (
    <Stack
      sx={{
        position: "relative",
        borderRadius: 1,
        overflow: "hidden",
        width: "100%",
        maxWidth: "100%",
        margin: "auto",
        pointerEvents: mouseListening ? "unset" : "none",
      }}
      alignItems="center"
      justifyContent="center"
      ref={containerRef}
    >
      {getOutMediaUrlLoading && (
        <Stack
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
            background: "#ccc",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ color: "white", textAlign: "center" }}>
            Please Wait...
            <br />
            Your processed video is loading
          </Box>
        </Stack>
      )}

      {mediaUrl?.url ? (
        <Box sx={{ position: "relative" }}>
          <DomVideoPlayer
            ref={videoRef}
            src={mediaUrl.url}
            controls={selectedFrameIndex === undefined && controls}
            size={size}
            onLoaded={onLoaded}
            onUpdate={onUpdate}
          />
          <BoundingBoxOverlay bbox={getCurrentBBox()} videoSize={size} />
        </Box>
      ) : getOutMediaUrlLoading ? (
        <Skeleton width="100%" height={400} />
      ) : (
        "Can not load the video! Please try again."
      )}
    </Stack>
  );
};
