import { FC, useContext, useEffect, useRef } from "react";
import AspectRatioBox from "src/components/atoms/AspectRatioBox";
import { useEventEmitter } from "src/components/hook/useEventEmitter";
import { TaskContext } from "src/context/TaskContext";

export const Human3d: FC<{ maxHeight: number }> = ({ maxHeight }) => {
  const { selectedPersonFrames, fps } = useContext(TaskContext);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { on, off } = useEventEmitter();

  useEffect(() => {
    const onChangeTime = (currentTime: number) => {
      const frameIndex = Math.round(currentTime * fps);

      if (frameIndex >= 0 && frameIndex < selectedPersonFrames.length) {
        const frameData = selectedPersonFrames[frameIndex];

        iframeRef.current?.contentWindow?.postMessage(
          {
            type: "3d_pose_update",
            data: frameData,
          },
          "*",
        );
      }
    };

    on(["changeTime_video", "changeTime_chart"], onChangeTime);
    return () => {
      off(["changeTime_video", "changeTime_chart"], onChangeTime);
    };
  }, [fps, off, on, selectedPersonFrames]);

  return (
    <AspectRatioBox maxWidth={maxHeight}>
      <iframe
        ref={iframeRef}
        id="3d-view-iframe"
        title="3d view"
        src="/3d-view/index.html"
        frameBorder="none"
      />
    </AspectRatioBox>
  );
};
