import { Delete as DeleteIcon, Menu as MenuIcon } from "@mui/icons-material";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Stack,
} from "@mui/material";
import { FC, useContext, useState, MouseEvent, useMemo } from "react";
import { getRebaScore } from "src/app/logic/assessment/reba/Reba";
import {
  AssessmentRo,
  LiftLowerCarryLoadDto,
  PushPullLoadDto,
} from "src/app/services/generatedApi";
import { DeleteDialog } from "src/components/molecules/assessment/DeleteDialog";
import { VideoPlayer } from "src/components/molecules/result/VideoPlayer/VideoPlayer";
import { AssessmentContext } from "src/context/AssessmentContext";
import { TaskContext } from "src/context/TaskContext";
import { twoDecimalPlaces } from "src/utils/chartsUtils";
import { getRebaScoresDetailItem } from "./help/rebaScoresDetail";
import { getResultsFromData, taskModesMapper } from "./mmh/constant";
import { CalcTaskModes } from "src/app/logic/assessment/mmh/constants";
import { getRulaScore } from "src/app/logic/assessment/rula/Rula";
import { getRulaScoresDetailItem } from "./help/rulaScoresDetail";

type AssessmentCardPropsType = {
  assessment: AssessmentRo;
  fileName: string;
  havePose: boolean;
};

export const AssessmentCard: FC<AssessmentCardPropsType> = ({
  assessment,
  fileName,
  havePose,
}) => {
  const { reset, setAssessmentPageIndex, setHelpMethod } =
    useContext(AssessmentContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDeleteDialog = () => {
    setOpenDelete(true);
    handleClose();
  };

  const { fps } = useContext(TaskContext);

  const seconds = useMemo(
    () => (fps && assessment.frameIndex ? assessment.frameIndex / fps : 0),
    [assessment.frameIndex, fps],
  );

  const score = useMemo(() => {
    if (assessment.method === "REBA" && assessment.data.reba) {
      return getRebaScore(assessment.data.reba);
    }
    if (assessment.method === "RULA" && assessment.data.rula) {
      return getRulaScore(assessment.data.rula);
    }
  }, [assessment.data.reba, assessment.data.rula, assessment.method]);

  const detailItem = score
    ? assessment.method === "REBA"
      ? getRebaScoresDetailItem(score)
      : getRulaScoresDetailItem(score)
    : undefined;

  const IconComponent = detailItem ? detailItem.icon : <></>;

  const mmhResult = useMemo(() => {
    const mmhDto = assessment?.data?.mmh;
    if (!mmhDto) return undefined;
    return mmhDto.mode === "PUSH"
      ? [
          getResultsFromData(
            CalcTaskModes.PUSH_INITIAL,
            mmhDto.data,
            (mmhDto.load as PushPullLoadDto).initialForce,
          ),
          getResultsFromData(
            CalcTaskModes.PUSH_SUSTAINED,
            mmhDto.data,
            (mmhDto.load as PushPullLoadDto).sustainedForce,
          ),
        ]
      : mmhDto.mode === "PULL"
        ? [
            getResultsFromData(
              CalcTaskModes.PULL_INITIAL,
              mmhDto.data,
              (mmhDto.load as PushPullLoadDto).initialForce,
            ),
            getResultsFromData(
              CalcTaskModes.PULL_SUSTAINED,
              mmhDto.data,
              (mmhDto.load as PushPullLoadDto).sustainedForce,
            ),
          ]
        : [
            getResultsFromData(
              taskModesMapper[mmhDto.mode].calculateModes[0],
              mmhDto.data,
              (mmhDto.load as LiftLowerCarryLoadDto).objectWeight,
            ),
          ];
  }, [assessment.data.mmh]);

  return (
    <Card sx={{ maxWidth: "300", background: "#efefef", position: "relative" }}>
      <IconButton
        sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}
        onClick={handleMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={openDeleteDialog}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <CardActionArea
        component="div"
        onClick={() => {
          reset({
            id: assessment.id,
            name: assessment.name,
            frameIndex: assessment.frameIndex || 0,
            assessmentDataDto: assessment.data,
            method: assessment.method,
            targetId: assessment.targetId,
          });
          setAssessmentPageIndex(2);
        }}
      >
        <CardHeader title={assessment.method} subheader={assessment.name} />
        {assessment.frameIndex !== undefined && (
          <CardMedia>
            <VideoPlayer
              fileName={fileName}
              controls={false}
              mouseListening={false}
              selectedFrameIndex={assessment.frameIndex}
            />
          </CardMedia>
        )}

        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ pb: 2 }}
            spacing={0.5}
          >
            {mmhResult &&
              mmhResult?.length > 0 &&
              !!mmhResult[0].data.MALE.acceptancePercentage &&
              !!mmhResult[0].data.FEMALE.acceptancePercentage && (
                <Stack>
                  <Typography align="left" width="100%">
                    {"Acceptable to: " +
                      mmhResult[0].data.MALE.acceptancePercentage +
                      "% of males"}
                  </Typography>
                  <Typography align="left" width="100%">
                    {"Acceptable to: " +
                      mmhResult[0].data.FEMALE.acceptancePercentage +
                      "% of females"}
                  </Typography>
                </Stack>
              )}

            {detailItem && (
              <>
                <Typography variant="h5" color="text.secondary" align="center">
                  {"Score: " + score}
                </Typography>
                <Tooltip title={detailItem.detail}>
                  <IconButton
                    sx={{ color: detailItem.color }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setHelpMethod(assessment.method);
                    }}
                  >
                    <IconComponent />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
          <Typography>
            {"Selected Frame: " + twoDecimalPlaces(seconds) + '"'}
          </Typography>
        </CardContent>
      </CardActionArea>
      <DeleteDialog
        id={assessment.id}
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
      />
    </Card>
  );
};
