import { AnatomicalPlane, AnglesRo } from "src/types/poseType";

export function isValidNumber(value: any): value is number {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
}

export function getAnatomicalPlaneEnum(
  plane: string,
): AnatomicalPlane | undefined {
  const planeMap: Record<string, AnatomicalPlane> = {
    sagittal: AnatomicalPlane.SAGITTAL,
    coronal: AnatomicalPlane.CORONAL,
    axial: AnatomicalPlane.AXIAL,
    rightWristSagittal: AnatomicalPlane.RIGHT_WRIST_SAGITTAL,
    rightWristCoronal: AnatomicalPlane.RIGHT_WRIST_CORONAL,
    leftWristSagittal: AnatomicalPlane.LEFT_WRIST_SAGITTAL,
    leftWristCoronal: AnatomicalPlane.LEFT_WRIST_CORONAL,
  };

  return planeMap[plane];
}

export function getNestedAngleValue(
  angles: AnglesRo,
  path: string,
): number | undefined {
  try {
    const parts = path.split(".");
    let value: any = angles;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (!value || typeof value !== "object") {
        return undefined;
      }

      if (part.endsWith("Plane")) {
        value = value[part];
        if (!value) return undefined;
        const planeProp = getAnatomicalPlaneEnum(parts[i + 1]);
        return planeProp ? value[planeProp] : undefined;
      }

      value = value[part];
    }

    return isValidNumber(value) ? value : undefined;
  } catch (error) {
    console.error(`Error getting nested angle value for path ${path}:`, error);
    return undefined;
  }
}

export function setNestedValue(obj: any, path: string, value: number): void {
  try {
    const parts = path.split(".");
    let current = obj;

    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      if (part.endsWith("Plane")) {
        current[part] = current[part] || {};
        current = current[part];

        const planeProp = getAnatomicalPlaneEnum(parts[i + 1]);
        if (planeProp) {
          current[planeProp] = value;
          return;
        }
      } else {
        current[part] = current[part] || {};
        current = current[part];
      }
    }

    const lastPart = parts[parts.length - 1];
    if (
      !lastPart.includes("sagittal") &&
      !lastPart.includes("coronal") &&
      !lastPart.includes("axial") &&
      !lastPart.includes("Wrist")
    ) {
      current[lastPart] = value;
    }
  } catch (error) {
    console.error(`Error setting nested value for path ${path}:`, error);
  }
}
