export enum AnatomicalPlane {
  SAGITTAL = "sagittal", // Divides body into left and right portions
  CORONAL = "coronal", // Divides body into front and back portions
  AXIAL = "axial", // Divides body into upper and lower portions
  RIGHT_WRIST_SAGITTAL = "right_wrist_sagittal", // For flexion/extension movements perpendicular to palm
  RIGHT_WRIST_CORONAL = "right_wrist_coronal", // For radial/ulnar movements parallel to palm
  LEFT_WRIST_SAGITTAL = "left_wrist_sagittal", // For flexion/extension movements perpendicular to palm
  LEFT_WRIST_CORONAL = "left_wrist_coronal", // For radial/ulnar movements parallel to palm
}

export class PlaneAnglesRo {
  [AnatomicalPlane.SAGITTAL]?: number;
  [AnatomicalPlane.CORONAL]?: number;
  [AnatomicalPlane.AXIAL]?: number;
  [AnatomicalPlane.RIGHT_WRIST_SAGITTAL]?: number;
  [AnatomicalPlane.RIGHT_WRIST_CORONAL]?: number;
  [AnatomicalPlane.LEFT_WRIST_SAGITTAL]?: number;
  [AnatomicalPlane.LEFT_WRIST_CORONAL]?: number;
}

export class AnglesRo {
  neckPlane?: PlaneAnglesRo;
  backPlane?: PlaneAnglesRo;
  rightKneePlane?: PlaneAnglesRo;
  leftKneePlane?: PlaneAnglesRo;
  rightUpperArmPlane?: PlaneAnglesRo;
  leftUpperArmPlane?: PlaneAnglesRo;
  rightLowerArmPlane?: PlaneAnglesRo;
  leftLowerArmPlane?: PlaneAnglesRo;
  rightWristPlane?: PlaneAnglesRo;
  leftWristPlane?: PlaneAnglesRo;
  neck?: number;
  back?: number;
  rightKnee?: number;
  leftKnee?: number;
  rightUpperArm?: number;
  leftUpperArm?: number;
  rightLowerArm?: number;
  leftLowerArm?: number;
  rightWrist?: number;
  leftWrist?: number;
}

export class PersonFrameRo {
  frameIndex: number = 0;
  bbox?: number[];
  keypoints?: number[][];
  angles?: AnglesRo;
  isBest?: boolean;
}
