import { mean } from "lodash";
import { SettingDto } from "src/app/services/generatedApi";
import { BodyPartState, StateToColorType } from "./types";
import { isWithinThreshold } from "src/utils/numericUtils";
import {
  settingKeys,
  SettingsNamesEnum,
} from "src/components/molecules/category/types";
import { green, yellow, red, orange, grey } from "@mui/material/colors";
import { AnglesRo } from "src/types/poseType";

export const defaultColor = "rgb(185, 185, 185)";

export const stateToColor: StateToColorType = {
  [BodyPartState.SAFE]: green[500],
  [BodyPartState.HAZARD]: red[500],
  [BodyPartState.CAUTION]: yellow[600],
  [BodyPartState.NONE]: defaultColor,
};

export const getStateColor = (state: BodyPartState) => {
  switch (state) {
    case BodyPartState.SAFE:
      return green;
    case BodyPartState.HAZARD:
      return red;
    case BodyPartState.CAUTION:
      return orange;
    default:
      return grey;
  }
};

export const getState = (
  setting: SettingDto,
  part: keyof SettingDto,
  angle?: number,
): BodyPartState => {
  if (!setting || angle === undefined) {
    return BodyPartState.NONE;
  }

  const { threshold1, threshold2, threshold3, threshold4 } = setting[part];

  if (
    isWithinThreshold(angle, threshold1, threshold2) ||
    isWithinThreshold(angle, threshold3, threshold4)
  ) {
    return BodyPartState.CAUTION;
  }
  if (isWithinThreshold(angle, threshold2, threshold3)) {
    return BodyPartState.SAFE;
  }
  return BodyPartState.HAZARD;
};

const initializeBodyPartStateCounts = (): Record<BodyPartState, number> => ({
  [BodyPartState.SAFE]: 0,
  [BodyPartState.CAUTION]: 0,
  [BodyPartState.HAZARD]: 0,
  [BodyPartState.NONE]: 0,
});

export const calcPartStateCount = (
  angles: AnglesRo[],
  setting: SettingDto,
  part: keyof SettingDto,
): Record<BodyPartState, number> => {
  const counts = initializeBodyPartStateCounts();
  angles?.forEach((item) => {
    const state = getState(setting, part, item?.[part]);
    counts[state]++;
  });
  return counts;
};

export const calcAllPartState = async (
  angles: AnglesRo[],
  setting: SettingDto,
  frameIndex?: number,
): Promise<Partial<Record<keyof SettingDto, BodyPartState>>> => {
  return settingKeys.reduce(
    (prev, part) => {
      const angle =
        frameIndex === undefined
          ? mean(
              angles
                ?.map((item) => item?.[part])
                .filter((item) => item !== undefined),
            )
          : angles?.[frameIndex]?.[part];

      prev[part] = getState(setting, part, angle);
      return prev;
    },
    {} as Partial<Record<keyof SettingDto, BodyPartState>>,
  );
};

export const calcPartState = (
  angles: AnglesRo[],
  setting: SettingDto,
  part: keyof SettingDto,
  frameIndex?: number,
): BodyPartState => {
  if (frameIndex === undefined) {
    const counts = calcPartStateCount(angles, setting, part);
    const sum =
      counts[BodyPartState.HAZARD] +
      counts[BodyPartState.CAUTION] +
      counts[BodyPartState.SAFE];

    if (sum * 4 < counts[BodyPartState.NONE]) {
      return BodyPartState.NONE;
    }

    const score =
      (counts[BodyPartState.HAZARD] + 0.5 * counts[BodyPartState.CAUTION]) /
      sum;

    if (score > 0.66) return BodyPartState.HAZARD;
    if (score > 0.33) return BodyPartState.CAUTION;
    return BodyPartState.SAFE;
  }

  const angle = angles?.[frameIndex]?.[part];
  return getState(setting, part, angle);
};

interface PartScore {
  score: number;
  visibleCount: number;
}

interface OverallScore {
  partScores: PartScore[];
  totalSafe: number;
  totalCaution: number;
  totalHazard: number;
}

export const calculatePartScores = (
  angles: AnglesRo[],
  setting: SettingDto,
): OverallScore => {
  let totalSafe = 0;
  let totalCaution = 0;
  let totalHazard = 0;

  const partScores = settingKeys.map((key) => {
    const counts = calcPartStateCount(angles, setting, key);
    totalSafe += counts[BodyPartState.SAFE];
    totalCaution += counts[BodyPartState.CAUTION];
    totalHazard += counts[BodyPartState.HAZARD];
    return {
      score:
        50 * counts[BodyPartState.CAUTION] + 100 * counts[BodyPartState.HAZARD],
      visibleCount:
        counts[BodyPartState.SAFE] +
        counts[BodyPartState.CAUTION] +
        counts[BodyPartState.HAZARD],
    };
  });

  return {
    partScores,
    totalSafe,
    totalCaution,
    totalHazard,
  };
};

interface RiskAssessment {
  overallRisk: number;
  safePercentage: number;
  cautionPercentage: number;
  hazardPercentage: number;
}

export const calculateOverallRisk = (
  angles: AnglesRo[],
  setting: SettingDto,
): RiskAssessment => {
  let safe = 0,
    hazard = 0,
    caution = 0;

  settingKeys.forEach((key) => {
    const counts = calcPartStateCount(angles, setting, key);
    safe += counts[BodyPartState.SAFE];
    hazard += counts[BodyPartState.HAZARD];
    caution += counts[BodyPartState.CAUTION];
  });

  const sum = hazard + caution + safe;
  const score = Math.round(((hazard + 0.5 * caution) / sum) * 100);
  const safePercentage = Math.round((safe / sum) * 100);
  const cautionPercentage = Math.round((caution / sum) * 100);
  const hazardPercentage = Math.round((hazard / sum) * 100);

  return {
    overallRisk: score,
    safePercentage,
    cautionPercentage,
    hazardPercentage,
  };
};

interface PartPercentages {
  name: string;
  Safe: number;
  Caution: number;
  Hazard: number;
  None: number;
}

export const calculatePartPercentages = (
  angles: AnglesRo[],
  setting: SettingDto,
): PartPercentages[] => {
  return settingKeys.map((item) => {
    const counts = calcPartStateCount(angles, setting, item);
    const sum = Object.values(counts).reduce((acc, val) => acc + val, 0);

    return {
      name: SettingsNamesEnum[item],
      Safe: roundToOneDecimal((counts[BodyPartState.SAFE] / sum) * 100),
      Caution: roundToOneDecimal((counts[BodyPartState.CAUTION] / sum) * 100),
      Hazard: roundToOneDecimal((counts[BodyPartState.HAZARD] / sum) * 100),
      None: roundToOneDecimal((counts[BodyPartState.NONE] / sum) * 100),
    };
  });
};

const roundToOneDecimal = (num: number): number => {
  return Math.round(num * 10) / 10;
};
