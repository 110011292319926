import { FC } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAppSelector } from "src/app/hooks";

type LineChartTooltipPropsType = {
  active?: boolean;
  payload?: {
    name: string;
    value: number;
    payload?: { frameNumber: number };
  }[];
  label?: string;
  isAssessment?: boolean;
};

const LineChartTooltip: FC<LineChartTooltipPropsType> = ({
  active,
  payload,
  label,
  isAssessment = false,
}) => {
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  if (active && payload && payload.length) {
    return (
      <Paper
        component={Stack}
        direction="column"
        rowGap={1}
        sx={{ p: 1, mx: 4 }}
        elevation={3}
      >
        <Stack direction="row" columnGap={1}>
          <Typography color={grey[500]}>
            {isAssessment ? "Score" : "Angle"}
          </Typography>
          <Typography>
            {payload[0].value === undefined ||
            payload[0].value === null ||
            isNaN(payload[0].value)
              ? "-"
              : payload[0].value + (isAssessment ? "" : "°")}
          </Typography>
        </Stack>
        <Stack direction="row" columnGap={1}>
          <Typography color={grey[500]}>Time</Typography>
          <Typography>{label}″</Typography>
        </Stack>
        {isAdmin && payload[0].payload?.frameNumber !== undefined && (
          <Stack direction="row" columnGap={1}>
            <Typography color={grey[500]}>Frame</Typography>
            <Typography>{payload[0].payload.frameNumber}</Typography>
          </Stack>
        )}
      </Paper>
    );
  }

  return null;
};

export default LineChartTooltip;
