import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Grow,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SaveDialog } from "./SaveDialog";
import { AssessmentContext } from "src/context/AssessmentContext";
import { VideoPlayer } from "src/components/molecules/result/VideoPlayer/VideoPlayer";
import { getRebaScore } from "src/app/logic/assessment/reba/Reba";
import { getRulaScore } from "src/app/logic/assessment/rula/Rula";
import { getRebaScoresDetailItem } from "src/components/organisms/assessment/help/rebaScoresDetail";
import { getRulaScoresDetailItem } from "src/components/organisms/assessment/help/rulaScoresDetail";

type FloatingCardProps = { fileName: string; havePose: boolean };

export const FloatingCard: FC<FloatingCardProps> = ({ fileName, havePose }) => {
  const {
    id,
    assessmentDataDto,
    setHelpMethod,
    frameIndex,
    targetId,
    selectedMethod,
  } = useContext(AssessmentContext);

  const [offset, setOffset] = useState(0);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const score = useMemo(() => {
    if (selectedMethod === "REBA" && assessmentDataDto?.reba) {
      return getRebaScore(assessmentDataDto.reba);
    }
    if (selectedMethod === "RULA" && assessmentDataDto?.rula) {
      return getRulaScore(assessmentDataDto.rula);
    }
    return 0;
  }, [assessmentDataDto, selectedMethod]);

  const detailItem = useMemo(() => {
    if (selectedMethod === "REBA") {
      return getRebaScoresDetailItem(score);
    }
    return getRulaScoresDetailItem(score);
  }, [score, selectedMethod]);

  const IconComponent = detailItem.icon;

  return (
    <>
      <Grow
        in={offset > 400}
        style={{ transformOrigin: "0 0 0" }}
        timeout={500}
      >
        <Stack
          sx={{
            position: "fixed",
            top: 80,
            right: 20,
            width: { xs: "50%", sm: "30%", md: "20%" },
            zIndex: 10,
            background: "#fefefedd",
            borderRadius: 1,
            border: "1px solid #ccc",
            maxHeight: { xs: "80%", sm: "60%" },
          }}
        >
          <VideoPlayer
            fileName={fileName}
            controls={false}
            selectedFrameIndex={frameIndex}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={1}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>
                Total Score: {score}
              </Typography>
              <Tooltip title={detailItem.detail}>
                <IconButton
                  sx={{ color: detailItem.color }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setHelpMethod(selectedMethod);
                  }}
                  size="small"
                >
                  <IconComponent fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Button variant="contained" onClick={() => setOpenSaveDialog(true)}>
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        </Stack>
      </Grow>
      <SaveDialog
        open={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
      />
    </>
  );
};
