import {
  FC,
  createContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import { getState } from "src/app/logic/pose/state";
import { BodyPartState } from "src/app/logic/pose/types";
import {
  SettingDto,
  TaskRo,
  usePoseControllerGetPersonsFramesQuery,
} from "src/app/services/generatedApi";
import { categoryDefaultSettings } from "src/components/molecules/category/constants";
import { decompress } from "src/helpers/compression/decompression";
import { AnglesRo, PersonFrameRo } from "src/types/poseType";

type TaskContextType = {
  taskId: string;
  setting: SettingDto;
  havePose: boolean;
  duration: number;
  fps: number;
  selectedAngles: AnglesRo[];
  selectedPersonFrames: PersonFrameRo[];
  selectedPersonBBoxes: number[][];
  setDuration: (seconds: number) => void;
  isSited: boolean;
  openNotes: boolean;
  selectedPersonId?: string;
  onChangeSelectedPersonId: (personId?: string) => void;
  changeSelectedPersonIdIfNeeded: (
    personId: string,
    frameIndex: number,
  ) => void;
  setOpenNotes: (openNotes: boolean) => void;
};

export const TaskContext = createContext<TaskContextType>({
  taskId: "",
  setting: categoryDefaultSettings,
  havePose: false,
  duration: 0,
  fps: 30,
  selectedAngles: [],
  selectedPersonFrames: [],
  selectedPersonBBoxes: [],
  setDuration: () => {},
  isSited: false,
  openNotes: false,
  onChangeSelectedPersonId: () => {},
  changeSelectedPersonIdIfNeeded: () => {},
  setOpenNotes: () => {},
});

type TaskContextProviderPropsType = {
  task: TaskRo;
  children?: ReactNode;
};

const TaskContextProvider: FC<TaskContextProviderPropsType> = ({
  task,
  children,
}) => {
  const [duration, setDuration] = useState(0);
  const [selectedPersonId, setSelectedPersonId] = useState<string>();
  const [openNotes, setOpenNotes] = useState(false);

  const { data: compressed } = usePoseControllerGetPersonsFramesQuery({
    id: task.id,
  });

  const allPersonFrames = useMemo(() => {
    if (!compressed) {
      return {};
    }
    return decompress(compressed);
  }, [compressed]);

  const selectedPersonFrames = useMemo(() => {
    const personId = selectedPersonId || "best";

    let relevantFrames: PersonFrameRo[] = [];
    if (personId === "best") {
      relevantFrames = Object.values(allPersonFrames)
        .flat()
        .filter((frame) => frame.isBest)
        .sort((a, b) => a.frameIndex - b.frameIndex);
    } else {
      relevantFrames = allPersonFrames[personId] || [];
    }

    return relevantFrames;
  }, [allPersonFrames, selectedPersonId]);

  const selectedPersonBBoxes = useMemo(() => {
    const bboxMap = new Map<number, number[]>();

    selectedPersonFrames.forEach((frame) => {
      if (frame.bbox) {
        bboxMap.set(frame.frameIndex, frame.bbox);
      }
    });

    const bboxArray: number[][] = [];
    for (let i = 0; i < task.videoFrameLength!; i++) {
      bboxArray.push(bboxMap.get(i) || []);
    }

    return bboxArray;
  }, [selectedPersonFrames, task.videoFrameLength]);

  const selectedAngles = useMemo(() => {
    const frameMap = new Map<number, AnglesRo>();

    selectedPersonFrames.forEach((frame) => {
      if (frame.angles) {
        frameMap.set(frame.frameIndex, frame.angles);
      }
    });

    const anglesArray: AnglesRo[] = [];
    for (let i = 0; i < task.videoFrameLength!; i++) {
      anglesArray.push(frameMap.get(i) || {});
    }

    return anglesArray;
  }, [selectedPersonFrames, task.videoFrameLength]);

  const framesCount = useMemo(
    () => task.videoFrameLength || 0,
    [task.videoFrameLength],
  );

  const fps = useMemo(
    () => (duration === 0 ? 30 : framesCount / duration),
    [framesCount, duration],
  );

  const isSited = useMemo<boolean>(() => {
    return getState(task.setting, "back", 90) !== BodyPartState.HAZARD;
  }, [task.setting]);

  const onChangeSelectedPersonId = useCallback(
    (personId?: string) =>
      personId === undefined
        ? setSelectedPersonId(undefined)
        : setSelectedPersonId(personId),
    [setSelectedPersonId],
  );

  const changeSelectedPersonIdIfNeeded = useCallback(
    (personId: string, frameIndex: number) =>
      setSelectedPersonId((prev) => {
        if (prev === personId || typeof prev === "string") {
          return personId;
        }
        return prev;
      }),
    [],
  );

  return (
    <TaskContext.Provider
      value={{
        taskId: task.id,
        setting: task.setting,
        havePose: task.havePose,
        duration,
        fps,
        selectedAngles,
        selectedPersonFrames,
        selectedPersonBBoxes: selectedPersonBBoxes || [],
        setDuration,
        openNotes,
        isSited,
        selectedPersonId,
        onChangeSelectedPersonId,
        changeSelectedPersonIdIfNeeded,
        setOpenNotes,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskContextProvider;
