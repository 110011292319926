import { FC } from "react";
import { Box } from "@mui/material";

const BoundingBoxOverlay: FC<{
  bbox: number[];
  videoSize: { width: number; height: number };
}> = ({ bbox, videoSize }) => {
  if (!bbox || bbox.length !== 4) return null;

  const [x, y, width, height] = bbox;

  const pixelX = x * videoSize.width;
  const pixelY = y * videoSize.height;
  const pixelWidth = width * videoSize.width;
  const pixelHeight = height * videoSize.height;

  return (
    <Box
      sx={{
        position: "absolute",
        left: `${pixelX}px`,
        top: `${pixelY}px`,
        width: `${pixelWidth}px`,
        height: `${pixelHeight}px`,
        border: "2px solid #f44336",
        pointerEvents: "none",
      }}
    >
      s
    </Box>
  );
};

export default BoundingBoxOverlay;
